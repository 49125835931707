import { navigate } from "gatsby"
import React, { Component } from "react"
class IndexPage extends Component {
  componentDidMount() {
    navigate("/category/vrv/sub/outdoor-units")
  }

  render() {
    return <></>
  }
}

export default IndexPage
